<template>
  <div>
    <confirm-modal
      :title="$t('views.confirm.removeCountryCoverage.title')"
      :confirmMessage="$t('views.confirm.removeCountryCoverage.message')"
      :showConfirm="confirmModalVisible"
      @confirm:ok="removeCountry"
      @confirm:cancel="closeConfirmModal">
    </confirm-modal>
    <Main>
      <a-row :gutter="15">
        <a-col :md="12" :xs="24">
          <ListStyleWrapper>
            <sdCards :title="$t('views.registration.countries')" class="countries-card">
              <countries-list
                :countries="filteredCountries"
                listFunction="add"
                view="agentRegistration"
                :border="true"
                @addCountry="addCountry"
                :disableAdd="reapplyMode"
              />
            </sdCards>
          </ListStyleWrapper>
        </a-col>
        <a-col :md="12" :xs="24">
          <ListStyleWrapper>
            <sdCards :title="$t('views.registration.selectedCountries')" class="countries-card">
              <countries-list
                :countries="countriesCoverage"
                listFunction="remove"
                view="agentRegistration"
                :border="true"
                @removeCountry="confirmRemoveCountry"
              />
            </sdCards>
          </ListStyleWrapper>
        </a-col>
      </a-row>
      <div class="sDash_form-action">
        <a-button
          class="btn-signin mr-10"
          size="small"
          type="default"
          @click="buttonClicked($event, 'previous')"
        >
          <span>{{$t('actions.previous')}}</span>
        </a-button>
        <span class="float-button-right">
          <a-button
            class="btn-signin mr-10"
            size="small"
            type="primary"
            :loading="loading"
            :disabled="loading"
            @click="buttonClicked($event, 'saveAndNext:countriesCoverage')"
          >
            <span>{{companyEditMode ? $t('actions.saveAndNext') : $t('actions.saveDraftAndNext')}}</span>
          </a-button>
        </span>
        <span v-if="!companyEditMode" class="float-button-right">
          <a-button
            class="btn-signin mr-10"
            size="small"
            type="primary"
            :loading="loading"
            :disabled="loading"
            @click="buttonClicked($event, 'saveDraft:countriesCoverage')"
          >
            <span>{{$t('actions.saveDraft')}}</span>
          </a-button>
        </span>
      </div>
    </Main>
  </div>
</template>

<script>
import {
  computed, defineComponent, ref, watch
} from 'vue';
import {useStore} from 'vuex';
import {Main} from '@/components/shared/styledComponents/Main';
import CountriesList from '@/components/shared/cards/CountriesList';
import {ListStyleWrapper} from '@/components/shared/cards/countriesListStyle';
import _ from 'lodash';
import VueTypes from 'vue-types';
import {displayServerErrorsInToaster} from '@/helpers/toasters';
import {useI18n} from 'vue-i18n';
import ConfirmModal from '@/components/modals/ConfirmModal';

export default defineComponent({
  name: 'ClientCompanyDetails',
  emits: ['previous', 'saveAndNext:countriesCoverage', 'saveDraft:countriesCoverage'],
  components: {
    CountriesList,
    ListStyleWrapper,
    Main,
    ConfirmModal
  },
  props: {
    data: VueTypes.oneOfType([VueTypes.array, VueTypes.object]),
    loading: VueTypes.bool.def(false),
    editMode: VueTypes.bool.def(false),
    companyEditMode: VueTypes.bool.def(false),
    reapplyMode: VueTypes.bool.def(false)
  },
  setup(props, context) {
    const {getters} = useStore();
    const countries = computed(() => getters.countries);
    const countriesCoverage = ref([]);

    const addCountry = (country) => {
      countriesCoverage.value.push(_.find(countries.value, (c) => c.value === country));
    };

    watch(() => props.data, (countries) => {
      countriesCoverage.value = [];
      _.forEach(countries, (country) => addCountry(country));
    }, {immediate: true, deep: true});

    const filteredCountries = computed(() => {
      countriesCoverage.value;
      return _.difference(countries.value, countriesCoverage.value);
    });

    const confirmModalVisible = ref(false);
    const countryToRemove = ref(false);

    const confirmRemoveCountry = (country) => {
      countryToRemove.value = country;
      if (props.editMode) confirmModalVisible.value = true;
      else removeCountry();
    };

    const closeConfirmModal = () => {
      countryToRemove.value = '';
      confirmModalVisible.value = false;
    };

    const removeCountry = () => {
      const index = countriesCoverage.value.indexOf(countryToRemove.value);
      if (index > -1) countriesCoverage.value.splice(index, 1);
      closeConfirmModal();
    };

    const {t} = useI18n();
    const buttonClicked = (e, emitEvent) => {
      e.preventDefault();
      if (emitEvent !== 'previous' && countriesCoverage.value.length === 0) {
        displayServerErrorsInToaster([t('messages.error.countriesCoverageRequired')]);
        return;
      }
      context.emit(emitEvent, _.map(countriesCoverage.value, 'value'));
    };

    return {
      filteredCountries,
      countriesCoverage,
      addCountry,
      confirmModalVisible,
      closeConfirmModal,
      removeCountry,
      confirmRemoveCountry,
      buttonClicked
    };
  }
});
</script>

<style lang="scss" scoped>
.countries-card {
  border: 2px solid #f0efef !important;
}

.sDash_form-action button {
  border-radius: 4px;
  padding: 6.4px 29.2px;
  height: 44px;
}

@media only screen and (max-width: 500px){
  .sDash_form-action button,
  .sDash_form-action span {
    width: 100% !important;
  }
}
</style>
