<template>
  <table-style-wrapper>
    <table-wrapper class="table-responsive">
      <a-table
        size="small"
        :columns="columns"
        :data-source="$_.filter(prices, {_destroy: 0})"
        :pagination="false"
        :rowKey="(record) => $_.indexOf(prices, record)"
        :locale="{emptyText: $t('views.registration.noMandatory')}"
      >
        <template #itemName="{record}">
          {{$_.find(categoryItems, (item) => item._jv.id === record.priceCategoryItemId)?.itemName}}
        </template>
        <template #suggestedPercent="{record}">
          {{`${record.suggestedPercent ? record.suggestedPercent + '%' : ''}`}}
        </template>
        <template v-for="col in ['min', 'percent']" :key="col" v-slot:[col]="{record}">
          <number-field
            class="editable-table-cell hide-number-handler"
            :rules="[requiredNumber, nonNegative]"
            size="small"
            :max="col === 'percent' ? 100 : undefined"
            :field-value="prices[$_.indexOf(prices, record)][col]"
            :fieldKey="[category, $_.indexOf(prices, record), col]"
            :percentage="col === 'percent'"
            @update:fieldValue="inputChanged(col, $_.indexOf(prices, record), $event)"
          />
        </template>

        <template #comment="{record}">
          <text-area
            :field-value="prices[$_.indexOf(prices, record)]['comment']"
            @update:fieldValue="inputChanged('comment', $_.indexOf(prices, record), $event)"
            :fieldKey="[category, $_.indexOf(prices, record), 'comment']"
          />
        </template>

        <template #actions="{record}">
          <remove-item-button
            v-if="!isMandatory(record)"
            :record="record"
            _classes="single-table-action"
            @removeItem="handleRemove(record)"
          />
        </template>

        <template #footer v-if="!$_.isEmpty(activeOptionalCategoryItems)">
          <a-row>
            <a-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" :xxl="8">
              <dropdown
                icon="plus-circle"
                :options="activeOptionalCategoryItems"
                @selectedOption="addOptionalItem"
              />
          </a-col>
          </a-row>
        </template>
      </a-table>
    </table-wrapper>
  </table-style-wrapper>
</template>

<script>
import {
  computed, defineComponent, ref, watch, reactive
} from 'vue';
import {useI18n} from 'vue-i18n';
import VueTypes from 'vue-types';
import _ from 'lodash';
import {useStore} from 'vuex';
import {TableStyleWrapper} from '@/components/tables/style.js';
import {TableWrapper} from '@/components/shared/styledComponents/Main';
import NumberField from '@/components/shared/fields/Number';
import TextArea from '@/components/shared/fields/TextArea';
import {requiredNumber, nonNegative} from '@/helpers/validationRules';
import Dropdown from '@/components/dropdown/Dropdown';
import RemoveItemButton from '@/components/registration/RemoveItemButton';

export default defineComponent({
  name: 'AgentPriceListTableForm',
  components: {
    TableWrapper,
    TableStyleWrapper,
    NumberField,
    TextArea,
    Dropdown,
    RemoveItemButton
  },
  props: {
    country: VueTypes.string,
    category: VueTypes.string,
    unsavedPrices: VueTypes.array.def([])
  },
  emits: ['updateSubForm'],
  setup(props, context) {
    const {getters} = useStore();
    const {t} = useI18n();

    const columns = computed(() => [
      {
        title: t('models.suggestedPrice.attributes.priceCategoryItemId'),
        dataIndex: 'priceCategoryItemId',
        slots: {customRender: 'itemName'}
      },
      {
        title: t('models.priceList.attributes.min'),
        slots: {customRender: 'min'}
      },
      {
        title: t('models.suggestedPrice.attributes.suggestedMin'),
        dataIndex: 'suggestedMin'
      },
      {
        title: t('models.priceList.attributes.percent'),
        slots: {customRender: 'percent'}
      },
      {
        title: t('models.suggestedPrice.attributes.suggestedPercent'),
        slots: {customRender: 'suggestedPercent'}
      },
      {
        title: t('models.priceList.attributes.comment'),
        slots: {customRender: 'comment'}
      },
      {
        title: '',
        slots: {customRender: 'actions'}
      }
    ]);

    const categoryItems = computed(() => _.filter(getters.categoryItems, {categoryType: props.category}));
    const suggestedPrices = computed(() => getters.suggestedPrices);
    const priceLists = computed(() => getters.companyPriceLists[props.country]?.[props.category] || []);
    const prices = ref([]);
    const optionalCategoryItems = ref([]);
    const unsavedPrices = reactive(props.unsavedPrices);

    const priceSetForCategoryItem = (item) => _.find(priceLists.value, (p) => {
      return p._jv.relationships.priceCategoryItem._jv.id === item.value;
    });
    const activeOptionalCategoryItems = computed(() => {
      optionalCategoryItems.value;
      const active = _.filter(optionalCategoryItems.value, (item) => !item.archived || priceSetForCategoryItem(item));
      return active;
    });

    const addMandatoryItems = () => {
      _.forEach(categoryItems.value, (item) => {
        if (item.mandatory) {
          addItem(item);
        } else {
          optionalCategoryItems.value.push({
            label: item.itemName,
            value: item._jv.id,
            archived: !!item.deletedAt
          });
        }
      });
    };

    const addOptionalItems = () => {
      const optionalItems = [...optionalCategoryItems.value];
      _.forEach(optionalItems, (item) => {
        if (priceSetForCategoryItem(item)) addOptionalItem(item.value);

        const unsavedPrice = _.find(unsavedPrices, (price) => price.priceCategoryItemId === item.value);
        if (!unsavedPrice) return;

        const isAlreadyAdded = _.find(prices.value, (p) => p.priceCategoryItemId === unsavedPrice.priceCategoryItemId);
        if (!isAlreadyAdded) addOptionalItem(item.value);
      });
    };

    const init = () => {
      prices.value = [];
      optionalCategoryItems.value = [];

      addMandatoryItems();
      addOptionalItems();
      Object.assign(unsavedPrices, {});

      context.emit('updateSubForm', prices.value);
    };

    const addOptionalItem = (id) => {
      if (!_.find(unsavedPrices, {priceCategoryItemId: id, _destroy: 1})) {
        _.remove(optionalCategoryItems.value, (item) => item.value === id);
      }

      const price = _.find(prices.value, (item) => item.priceCategoryItemId === id);
      if (price) {
        price._destroy = 0;
        return;
      }

      addItem(_.find(categoryItems.value, (item) => item._jv.id === id));
    };

    const addItem = (item) => {
      const suggestedPrice = _.find(suggestedPrices.value, (p) => {
        return p._jv.relationships.priceCategoryItem.data.id === item._jv.id;
      });
      const price = _.find(priceLists.value, (p) => p._jv.relationships.priceCategoryItem._jv.id === item._jv.id);
      const unsavedPrice = _.find(unsavedPrices, (price) => price.priceCategoryItemId === item._jv.id);

      if (item.deletedAt && !price) return; // do not include archived items

      prices.value.push({
        id: price?._jv.id || '',
        min: unsavedPrice?.min || price?.min || 0,
        percent: unsavedPrice?.percent || price?.percent || 0,
        comment: unsavedPrice?.comment || price?.comment || '',
        priceCategoryItemId: item._jv.id,
        countryCode: props.country,
        suggestedPriceId: suggestedPrice?._jv.id,
        suggestedMin: suggestedPrice?.suggestedMin,
        suggestedPercent: suggestedPrice?.suggestedPercent,
        _destroy: unsavedPrice?._destroy || 0
      });
    };

    const isMandatory = (item) => {
      return _.find(categoryItems.value, (record) => record._jv.id === item.priceCategoryItemId)?.mandatory;
    };

    const inputChanged = (key, index, value) => {
      prices.value[index][key] = value;
      context.emit('updateSubForm', prices.value);
    };

    const handleRemove = (price) => {
      removeItem(price);
      const categoryItem = _.find(categoryItems.value, (item) => item._jv.id === price.priceCategoryItemId);
      optionalCategoryItems.value.push({
        label: categoryItem.itemName,
        value: categoryItem._jv.id,
        archived: !!categoryItem.deletedAt
      });

      context.emit('updateSubForm', prices.value);
    };

    const removeItem = (price) => {
      if (!price.id) {
        _.remove(prices.value, price);
        return;
      }
      price._destroy = 1;
      price.min = price.percent = 0;
      price.comment = '';
    };

    watch(priceLists, () => {
      init();
    }, {immediate: true, deep: true});

    return {
      columns,
      prices,
      categoryItems,
      optionalCategoryItems,
      activeOptionalCategoryItems,
      requiredNumber,
      nonNegative,
      inputChanged,
      addOptionalItem,
      isMandatory,
      handleRemove
    };
  }
});
</script>
