<template>
  <Main>
    <div class="auth-contents registration-form">
      <a-row :gutter="15">
        <a-col class="mt-10" :xs="24">
          <sdCards headless>
            <basic-steps step="withIcon" size="default" :data="stepsWithStatus" :current="current" />
          </sdCards>
        </a-col>
        <a-col class="mt-10" :xs="24" v-if="reapplyMode && current !== 2 &&
          stepsWithStatus[current]?.description === '(rejected)'">
          <sdCards headless>
            <h3>{{$t('models.companyProfileStep.attributes.rejectionReason')}}:</h3>
            {{$_.find(companyProfileSteps?.steps, {name: stepsWithStatus[current].key})?.rejectionReason || '--'}}
          </sdCards>
        </a-col>
        <a-col :xs="24">
          <company-details
            v-if="current === 0"
            :data="formState.companyDetails"
            :loading="buttonLoading"
            :showIorEorField="true"
            @submit:companyDetails="handleCompanyDetails"
            :companyEditMode="true"
          />
          <countries-coverage
            v-if="current === 1"
            :data="formState.countriesCoverage"
            :loading="buttonLoading"
            @previous="previous('countriesCoverage', $event)"
            @saveAndNext:countriesCoverage="submitCountriesCoverage($event, true)"
            @saveDraft:countriesCoverage="submitCountriesCoverage($event, false)"
            :companyEditMode="true"
            :reapplyMode="reapplyMode"
          />
          <price-list
            v-if="current === 2"
            :countries="formState.countriesCoverage"
            :data="formState.priceList"
            :loading="buttonLoading"
            :companyEditMode="true"
            :reapplyMode="reapplyMode"
            :companyId="currentUser?._jv?.relationships?.company?._jv?.id"
            :priceListSteps="companyProfileSteps.priceListSteps"
            @next="reapplyMode ? current++ : finishEdit()"
            @previous="previous('priceList', $event)"
          />
           <terms-and-conditions
            v-show="reapplyMode && current === 3"
            :loading="buttonLoading"
            :value="formState.termsAndConditions"
            @previousStep="current--"
            @finishRegistration="saveStep"
          />
        </a-col>
      </a-row>
    </div>
  </Main>
</template>

<script>
import {
  computed,
  defineComponent, reactive, ref, onMounted, watch
} from 'vue';
import {Main} from '@/components/shared/styledComponents/Main';
import BasicSteps from '@/components/steps/Basic';
import CompanyDetails from '@/components/registration/CompanyDetails';
import CountriesCoverage from '@/components/registration/CountriesCoverage';
import PriceList from '@/components/registration/PriceList';
import TermsAndConditions from '@/components/registration/TermsAndConditions';
import {useStore} from 'vuex';
import {useI18n} from 'vue-i18n';
import _ from 'lodash';
import {useRouter} from 'vue-router';

export default defineComponent({
  components: {
    BasicSteps,
    Main,
    CompanyDetails,
    CountriesCoverage,
    PriceList,
    TermsAndConditions
  },
  setup() {
    const {
      dispatch, state, getters
    } = useStore();

    const currentUser = computed(() => state.session.currentUser);

    dispatch('toggleLoading', true);
    Promise.all([
      dispatch('getCountries'),
      dispatch('getList', 'PRODUCT_CATEGORIES'),
      dispatch('getCompanyProfileSteps', currentUser.value._jv?.relationships.company._jv.id)
    ]).then(() => dispatch('toggleLoading', false));

    const initAgent = () => {
      if (currentUser.value) {
        const companyDetails = {};
        _.forEach(['email', 'firstName', 'lastName'], (key) => companyDetails[key] = currentUser.value[key]);
        _.forEach(['serviceTypes', 'city', 'country', 'street', 'name',
          'phoneCode', 'registrationNumber', 'contactPersonName', 'contactPersonPosition'], (key) => {
          companyDetails[key] = currentUser.value._jv.relationships.company[key] || undefined;
        });
        companyDetails['productCategoryIds'] = _.map(currentUser.value._jv.relationships.company.productCategories, ({id}) => id.toString());
        companyDetails['phone'] = parseInt(currentUser.value._jv.relationships.company['phone']) || undefined;
        _.forEach(['tradeLicense', 'businessRegistrationCertificate'], (key) => {
          companyDetails[key] = [{
            uid: currentUser.value._jv.relationships.company[key]?.id,
            name: currentUser.value._jv.relationships.company[key]?.filename,
            status: 'done',
            url: `${process.env.VUE_APP_GCE_BASE_URL}${currentUser.value._jv.relationships.company[key]?.url}`
          }];
        });
        saveState('companyDetails', companyDetails);
        saveState('countriesCoverage', currentUser.value._jv.relationships.company.countriesCoverage);
      }
    };

    onMounted(() => {
      initAgent();
    });

    const companyProfileSteps = computed(() => getters.companyProfileSteps);
    const reapplyMode = state.session.currentUser?._jv.relationships.company?.status === 'rejected';
    const buttonLoading = ref(false);
    const current = ref(0);

    const formState = reactive({
      companyDetails: {}, countriesCoverage: [], priceList: {}, termsAndConditions: true
    });
    const saveState = (key, data) => {
      if (typeof data === 'object') {
        Object.assign(formState[key], {...formState[key], ...data});
      } else {
        formState[key] = data;
      }
    };

    const handleCompanyDetails = async (data) => {
      await saveStep('companyDetails', data, true);
      current.value += 1;
    };

    const previous = (stepKey, data) => {
      saveState(stepKey, data);
      current.value -= 1;
    };

    const submitCountriesCoverage = async (data, proceed) => {
      await saveStep('countriesCoverage', data, true);
      if (!proceed) return;

      current.value += 1;
    };

    const clearState = (key) => {
      formState[key] = {};
    };

    const saveStep = async (key, currentState, skipToaster) => {
      clearState(key);
      saveState(key, currentState);
      const formData = new FormData();
      formatCompanyDetails(formData);

      buttonLoading.value = true;
      const requestData = {
        data: formData, saveDraft: current.value !== 3, skipToaster
      };
      await dispatch('updateCompanyRegistration', requestData);
      buttonLoading.value = false;
    };

    const formatCompanyDetails = (formData) => {
      if (reapplyMode && current.value !== 3) formData.append(_.snakeCase('skipUpdateStatus'), true);
      if (current.value === 0 || current.value === 1) {
        _.forEach(formState.companyDetails, (value, key) => {
          if ((key === 'tradeLicense' || key === 'businessRegistrationCertificate')) {
            if (value[0] instanceof File) formData.append(`${_.snakeCase('agentAttributes')}[${_.snakeCase(key)}]`, value[0]);
          } else if (key === 'serviceTypes' || key === 'productCategoryIds') {
            _.forEach(value, (option) => formData.append(`${_.snakeCase('agentAttributes')}[${_.snakeCase(key)}][]`, option));
          } else {
            formData.append(`${_.snakeCase('agentAttributes')}[${_.snakeCase(key)}]`, value);
          }
        });

        _.forEach(formState.countriesCoverage, (value) => {
          formData.append(`${_.snakeCase('agentAttributes')}[${_.snakeCase('countriesCoverage')}][]`, value);
        });
      } else if (current.value === 3) {
        formData.append(`${_.snakeCase('agentAttributes')}[id]`, currentUser.value._jv?.relationships.company._jv.id);
        formData.append(`${_.snakeCase('agentAttributes')}[${_.snakeCase('termsAndConditions')}]`, formState.termsAndConditions);
      }
    };

    const {t} = useI18n();
    const formSteps = [
      {
        id: 1,
        title: t('views.registration.companyDetails'),
        icon: 'home',
        key: _.snakeCase('companyDetails')
      },
      {
        id: 2,
        title: t('views.registration.countriesCoverage'),
        icon: 'map',
        key: _.snakeCase('countriesCoverage')
      },

      {
        id: 3,
        title: t('views.registration.priceList'),
        icon: 'dollar-sign',
        key: _.snakeCase('priceLists')
      }
    ];
    const router = useRouter();

    const stepsWithStatus = computed(() => {
      if (_.isEmpty(companyProfileSteps.value?.steps)) return [];
      const basicSteps = formSteps;
      if (!reapplyMode) return basicSteps;

      basicSteps.push({
        id: 4,
        title: t('views.registration.termsAndConditions'),
        icon: 'clipboard',
        key: 'agreement'
      });

      _.forEach(basicSteps, (step) => {
        const stepStatus = _.find(companyProfileSteps.value.steps, {name: step.key})?.status;
        if (!stepStatus) return;
        step['description'] = stepStatus === 'unset' ? '' : `(${stepStatus})`;
      });

      return basicSteps;
    });

    watch(stepsWithStatus, (value) => {
      if (!_.isEmpty(value) && reapplyMode) {
        const firstRejectedStep = _.findIndex(stepsWithStatus.value, (step) => {
          return step.description === '(rejected)';
        });
        current.value = firstRejectedStep > -1 ? firstRejectedStep : 0;
      }
    }, {immediate: true, deep: true});

    const finishEdit = async () => {
      if (state.session.currentUser._jv.relationships.company.status !== _.snakeCase('csVerificationRequired')) {
        router.push({name: 'AgentCompanyProfile'});
        return;
      }

      router.push({
        name: 'AgentRegistrationSuccess',
        query: {
          edit: true
        }
      });
    };

    return {
      buttonLoading,
      current,
      formState,
      reapplyMode,
      stepsWithStatus,
      companyProfileSteps,
      saveStep,
      saveState,
      handleCompanyDetails,
      previous,
      formSteps,
      currentUser,
      submitCountriesCoverage,
      finishEdit
    };
  }
});
</script>

<style lang="scss" scoped>
.continue-registration-link a{
  color: #F79742;
}

.flex-col {
  display: flex;
}
.company-logo {
  flex: 1;
  text-align: center;
}

.company-logo img {
  width: 70px !important;
}

@media only screen and (max-width: 460px){

  .company-logo {
    margin-right: 20px
  }
}
</style>

