<template>
  <div>
    <Main>
      <a-row :gutter="15">
        <a-col :md="24" :xs="24">
          <sdCards headless>
            <a-row class="current-step">
              <a-col :xs="24" :sm="8">
                <h3>{{$t('views.registration.country')}}:</h3>
                <template v-if="!companyEditMode || reapplyMode">
                  {{$_.find(countriesList, (c) => c.value === country)?.label}}
                </template>
                <template v-else>
                  <a-select
                    size="small"
                    :show-arrow="true"
                    :options="countriesOptions"
                    :value="country"
                    @change="selectCountry"
                  />
                </template>
              </a-col>
              <a-col :xs="24" :sm="8">
                <template v-if="summaryMode">
                  {{$t('views.registration.priceListSummary')}}
                </template>
              </a-col>
            </a-row>
          </sdCards>
        </a-col>
        <a-col :md="24" :xs="24">
          <price-list-summary
            v-if="summaryMode"
            :country="country"
            :companyId="companyId"
          />
          <price-lists-form
            v-else
            ref="formRef"
            :country="country"
            :unsavedPrices="unsavedPrices[country]"
            :unsavedHsColumns="unsavedHsColumns[country]"
            :priceListSteps="priceListSteps"
            :reapplyMode="reapplyMode"
            @formSubmitted="afterSubmit"
          />
        </a-col>
      </a-row>

      <div class="sDash_form-action">
        <a-button
          class="btn-signin mr-10"
          size="small"
          type="default"
          @click="previous($event)"
        >
          <span>{{$t('actions.previous')}}</span>
        </a-button>
        <span class="float-button-right">
          <a-button
            :key="`priceListButton_${summaryMode}`"
            class="btn-signin mr-10"
            size="small"
            type="primary"
            :loading="loading"
            :disabled="loading"
            @click="summaryMode ? next() : submit($event, true)"
          >
            <span v-if="summaryMode">{{$t('actions.next')}}</span>
            <span v-else>{{companyEditMode ? $t('actions.saveAndNext') : $t('actions.saveDraftAndNext') }}</span>
          </a-button>
        </span>
        <span v-if="!companyEditMode && !summaryMode" class="float-button-right">
          <a-button
            class="btn-signin mr-10"
            size="small"
            type="primary"
            :loading="loading"
            :disabled="loading"
            @click="submit($event, false)"
          >
            <span>{{$t('actions.saveDraft')}}</span>
          </a-button>
        </span>
      </div>
    </Main>
  </div>
</template>

<script>
import {
  computed, defineComponent, reactive, ref, watch
} from 'vue';
import {useI18n} from 'vue-i18n';
import {useStore} from 'vuex';
import {Main} from '@/components/shared/styledComponents/Main';
import VueTypes from 'vue-types';
import _ from 'lodash';
import PriceListSummary from './PriceListSummary';
import PriceListsForm from './PriceListsForm';

export default defineComponent({
  name: 'AgentPriceList',
  emits: ['next', 'previous'],
  components: {
    Main,
    PriceListSummary,
    PriceListsForm
  },
  props: {
    data: VueTypes.object.def({}),
    countries: VueTypes.oneOfType([VueTypes.array, VueTypes.object]),
    companyId: VueTypes.string,
    companyEditMode: VueTypes.bool.def(false),
    reapplyMode: VueTypes.bool.def(false),
    priceListSteps: VueTypes.object.def({})
  },
  setup(props, context) {
    const {t} = useI18n();
    const {getters, dispatch} = useStore();

    const country = ref('');
    const summaryMode = ref(false);
    const categoryTypes = computed(() => getters.categoryTypes);
    const availableCountries = ref([]);
    const countriesList = computed(() => getters.countries);
    const countriesOptions = computed(() => {
      if (!props.companyEditMode || props.reapplyMode) return [];
      return _.map(availableCountries.value, (country) => {
        return _.find(countriesList.value, (c) => c.value === country);
      });
    });

    watch(() => props.countries, () => {
      if (!_.isEmpty(props.countries) && !props.reapplyMode) {
        availableCountries.value = props.countries;
        country.value = props.countries[0];
      }
    }, {deep: true, immediate: true});

    watch(() => [props.reapplyMode, props.priceListSteps], () => {
      if (!props.reapplyMode || _.isEmpty(props.priceListSteps)) return;
      const rejectedSteps = _.filter(props.priceListSteps, {status: 'rejected'});
      availableCountries.value = _.keys(_.groupBy(rejectedSteps, 'countryCode'));
      country.value = props.countries[0];
    }, {immediate: true, deep: true});


    const loading = ref(false);
    const formRef = ref();
    const proceed = ref(false);

    const nextBtn = computed(() => summaryMode.value ? t('actions.next') : t('actions.saveAndNext'));
    const nextCountryIndex = computed(() => _.indexOf(_.values(availableCountries.value), country.value) + 1);
    const hasNextCountry = computed(() => nextCountryIndex.value < _.values(availableCountries.value).length);

    const next = async () => {
      if (!hasNextCountry.value) return context.emit('next');

      country.value = availableCountries.value[nextCountryIndex.value];
      summaryMode.value = false;
    };

    const submit = async (e, canProceed) => {
      e.preventDefault();

      proceed.value = canProceed;
      loading.value = true;
      formRef.value.submitPricelist(canProceed);
    };

    const afterSubmit = async (isSuccessful) => {
      loading.value = false;
      if (!isSuccessful) return;

      if (!proceed.value) {
        loading.value = true;
        await dispatch('getHsCodesColumns', {
          params: {[_.snakeCase('countryCode')]: country.value},
          companyId: props.companyId
        });
        loading.value = false;
        return;
      }

      unsavedPrices[country.value] = undefined;
      unsavedHsColumns[country.value] = undefined;

      await getPriceListSummary();
      summaryMode.value = true;
    };

    const unsavedPrices = reactive(props.data['prices'] || {});
    const unsavedHsColumns = reactive(props.data['columns'] || {});
    const previousCountryIndex = computed(() => _.indexOf(_.values(availableCountries.value), country.value) - 1);
    const hasPreviousCountry = computed(() => previousCountryIndex.value >= 0);

    const previous = async (e) => {
      e.preventDefault();

      if (summaryMode.value) {
        summaryMode.value = false;
        return;
      }

      handleUnsavedEntries();

      if (hasPreviousCountry.value) {
        dispatch('toggleLoading', true);
        country.value = availableCountries.value[previousCountryIndex.value];
        await getPriceListSummary();
        summaryMode.value = true;
        dispatch('toggleLoading', false);
        return;
      }

      context.emit('previous', {prices: unsavedPrices, columns: unsavedHsColumns});
    };

    const getPriceListSummary = async () => {
      await Promise.all([
        dispatch('getPriceLists', {[_.snakeCase('countryCode')]: country.value}),
        dispatch('getHsCodesColumns', {
          params: {[_.snakeCase('countryCode')]: country.value},
          companyId: props.companyId
        })
      ]);
    };

    const selectCountry = (selection) => {
      handleUnsavedEntries();

      country.value = selection;
      summaryMode.value = false;
    };

    const handleUnsavedEntries = () => {
      unsavedPrices[country.value] = _.mapValues(formRef.value?.formData, (category) => {
        return _.filter(category, (item) => item.countryCode === country.value);
      });
      unsavedHsColumns[country.value] = _.pickBy(formRef.value?.columnData, (col) => {
        return col.country_code === country.value;
      });
    };

    return {
      countriesOptions,
      countriesList,
      categoryTypes,
      unsavedPrices,
      unsavedHsColumns,
      summaryMode,
      nextBtn,
      country,
      formRef,
      loading,
      submit,
      afterSubmit,
      next,
      previous,
      selectCountry
    };
  }
});
</script>

<style lang="scss" scoped>
.sDash_form-action button {
  border-radius: 4px;
  padding: 6.4px 29.2px;
  height: 44px;
}

@media only screen and (max-width: 500px){
  .sDash_form-action button,
  .sDash_form-action span {
    width: 100% !important;
  }
}

.current-step h3 {
  display: inline-block
}
</style>
