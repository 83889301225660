<template>
  <FormValidationWrap>
    <VerticalFormStyleWrap>
      <a-form
        v-if="!dataLoading"
        ref="formRef"
        name="sDash_validation-form"
        layout="vertical"
        :model="formData"
        :validateOnRuleChange="false"
      >
        <a-row :gutter="10">
            <a-col
              v-for="(category) in categoryTypes"
              :xs="24"
              :key="category"
            >
              <sdCards :title="$t(`models.priceCategory.attributes.categoryTypes.${category}`)">
                <price-list-table-form
                  v-if="category !== 'hs_code_list'"
                  ref="basicPriceListForm"
                  :category="category"
                  :country="country"
                  :unsavedPrices="unsavedPrices[category] || []"
                  @updateSubForm="formData[category] = $event"
                />
                <hs-price-list-table-form
                  v-else
                  ref="basicHSPriceListForm"
                  :category="category"
                  :country="country"
                  :unsavedPrices="unsavedPrices[category] || []"
                  :unsavedColumns="unsavedHsColumns"
                  @updateSubForm="formData[category] = $event"
                  @updateColumnNames="updateColumnNames"
                />

                <a-col
                  v-if="reapplyMode"
                  :xs="24" :sm="8"
                  :set="step = $_.find(priceListSteps, {category: category, countryCode: country})">
                  <h3 class="display-inline">{{$t('models.company.attributes.status')}}:</h3>
                  {{step?.['status'] || '--'}}
                  <br />
                  <h3 v-if="step?.['status'] === 'rejected'"
                    class="display-inline">{{$t('models.companyProfileStep.attributes.rejectionReason')}}:
                  </h3>
                  {{step?.['rejectionReason']}}
                </a-col>
              </sdCards>
            </a-col>
        </a-row>
      </a-form>
    </VerticalFormStyleWrap>
  </FormValidationWrap>
</template>

<script>
import {
  computed, defineComponent, reactive, ref, watch
} from 'vue';
import {useRoute} from 'vue-router';
import {useStore} from 'vuex';
import VueTypes from 'vue-types';
import {VerticalFormStyleWrap, FormValidationWrap} from '@/components/shared/form/Style';
import PriceListTableForm from './PriceListTableForm';
import HsPriceListTableForm from './HSPriceListTableForm';
import _ from 'lodash';
import {displayServerErrorsInToaster} from '@/helpers/toasters';
import {scrollToFirstErrorAlternative} from '@/helpers/scrollToFirstErrorInForm';
import {useI18n} from 'vue-i18n';

export default defineComponent({
  name: 'AgentPriceListsForm',
  components: {
    FormValidationWrap,
    VerticalFormStyleWrap,
    HsPriceListTableForm,
    PriceListTableForm
  },
  props: {
    country: VueTypes.string,
    unsavedPrices: VueTypes.object.def({}),
    unsavedHsColumns: VueTypes.object.def({}),
    reapplyMode: VueTypes.bool.def(false),
    priceListSteps: VueTypes.object.def({})
  },
  emits: ['formSubmitted'],
  setup(props, context) {
    const {
      dispatch, getters, state
    } = useStore();
    const {t} = useI18n();
    const route = useRoute();

    const companyId = computed(() => state.session.currentUser._jv?.relationships.company._jv.id);
    const categoryTypes = computed(() => getters.categoryTypes);
    const formData = reactive({});
    const columnData = reactive({});
    const skipUpdateStatus = computed(() => (route.name === 'AgentEdit' && props.reapplyMode) || route.name === 'AgentRegistrationForm');
    const loading = ref(false);
    const dataLoading = ref(false);

    const init = async () => {
      await dispatch('toggleLoading', true);
      dataLoading.value = true;
      await Promise.all([
        dispatch('getCategoryTypes'),
        dispatch('getCategoryItems'),
        dispatch('getSuggestedPrices', {[_.snakeCase('countryCode')]: props.country}),
        dispatch('getPriceLists', {[_.snakeCase('countryCode')]: props.country}),
        dispatch('getHsCodesColumns', {
          params: {[_.snakeCase('countryCode')]: props.country},
          companyId: companyId.value
        })
      ]);
      dataLoading.value = false;
      await dispatch('toggleLoading', false);
    };

    const updateColumnNames = (names) => {
      Object.assign(columnData, names);
    };

    const formRef = ref();

    const submitPricelist = (validate = true) => {
      if (validate && !validateHsCodesSelected()) return context.emit('formSubmitted', false);

      formRef.value
        .validate()
        .then(async () => {
          const formattedData = formatData();
          loading.value = true;
          const {success} = await dispatch('updatePriceLists', {
            data: {
              [_.snakeCase('skipUpdateStatus')]: skipUpdateStatus.value,
              [_.snakeCase('hsCodeItemColumnsAttributes')]: _.values(columnData),
              [_.snakeCase('priceListsAttributes')]: formattedData,
              [_.snakeCase('countryCode')]: props.country,
              [_.snakeCase('skipValidation')]: !validate
            }
          });
          loading.value = false;
          context.emit('formSubmitted', success);
        }).catch(({errorFields}) => {
          scrollToFirstErrorAlternative(formRef, errorFields, document, window);
          context.emit('formSubmitted', false);
        });
    };

    const validateHsCodesSelected = () => {
      if (_.every(formData[_.snakeCase('hsCodeList')], {'_destroy': true})) {
        displayServerErrorsInToaster([t('messages.error.selectHsCode')]);
        return false;
      }

      return true;
    };

    const preformattedKey = (key) => key === '_destroy' || key.includes('col');
    const formatData = () => {
      const formattedData = [];
      _.forEach(_.flatten(_.values(formData)), (price) => {
        if (!price.id && price._destroy) return;

        formattedData.push(_.mapKeys(price, (value, key) => preformattedKey(key) ? key : _.snakeCase(key)));
      });

      return formattedData;
    };

    watch(() => props.country, () => {
      init();
    }, {immediate: true, deep: true});

    return {
      formRef,
      categoryTypes,
      formData,
      columnData,
      dataLoading,
      updateColumnNames,
      submitPricelist
    };
  }
});
</script>
