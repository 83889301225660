import Styled from 'vue3-styled-components';

const ListStyleWrapper = Styled.div`
    .ant-card-body{
        text-align: center !important;
        padding: 15px 0 25px !important
    }
    .ant-table{
        width: 100%;
        thead{
            display: none;
        }
        tbody{
            tr{
                position: relative;
                &:hover{
                    box-shadow: 0 15px 50px ${({theme}) => theme['light-color']}20;
                    td{
                        background-color: #fff;
                    }
                }
                td{
                    font-size: 14px;
                    color: ${({theme}) => theme['gray-color']};
                    line-height: .75;
                    &:first-child{
                        padding-left: 25px;
                        padding-right: 8px;
                        width: 80%;
                    }
                    &:last-child{
                        padding-right: 40px;
                    }
                }
            }
            .list-item-actions{
                min-width: 100px;
                text-align: right;
                svg,
                i,
                span{
                    color: #D8DCEB !important;
                }
            }

        }
    }

    .table-responsive .ant-table-content .ant-table-cell,
    .table-responsive .ant-table-content tbody td,
    .table-responsive .ant-table-body td {
    white-space: nowrap !important;
    }
`;
export {ListStyleWrapper};
