<template>
  <template v-if="columnToEdit !== column">
    <span @click="editColumn(column)">
      {{currentColumnName}}
      <a class="float-right edit-column-name">
        <sdFeatherIcons type="edit-2" size="12" />
      </a>
    </span>
  </template>
  <template v-else>
    <a-row>
      <a-col :xs="23">
        <a-input
          ref="headerInput"
          size="small"
          v-model:value="columnFieldValue"
          class="column-name-input"
          :placeholder="$t('views.dynamicLists.enterValue')"
          @pressEnter="handleChangeColumn(column)"
          @blur="handleChangeColumn(column)"
        />
      </a-col>
      <a-col :xs="1">
        <a class="float-right cancel-edit-column-name" @click="clearEditColumn">
          <sdFeatherIcons type="x" size="18" />
        </a>
      </a-col>
    </a-row>
  </template>
</template>

<script>
import {
  defineComponent, ref, watch, nextTick
} from 'vue';
import VueTypes from 'vue-types';

export default defineComponent({
  name: 'EditableTableColumn',
  props: {
    columnToEdit: VueTypes.string.def(''),
    column: VueTypes.string.required,
    currentColumnName: VueTypes.string.required
  },
  emits: ['editColumn', 'closeEditColumn', 'updateColumn'],
  setup(props, context) {
    const columnFieldValue = ref('');

    const editColumn = (columnKey) => {
      columnFieldValue.value = props.currentColumnName,
      context.emit('editColumn', columnKey);
    };

    const clearEditColumn = () => {
      columnFieldValue.value = '';
      context.emit('closeEditColumn');
    };

    const closeEditColumn = () => {
      context.emit('closeEditColumn');
    };

    const handleChangeColumn = (column) => {
      const newValue = columnFieldValue.value;
      closeEditColumn();
      context.emit('updateColumn', newValue);
    };

    const headerInput = ref();
    watch(() => props.columnToEdit, async (col) => {
      if (col !== props.column) return;

      await nextTick();
      headerInput.value.focus();
    });

    return {
      columnFieldValue,
      headerInput,
      editColumn,
      clearEditColumn,
      closeEditColumn,
      handleChangeColumn
    };
  }
});
</script>

<style lang="scss">
.column-name-input {
  padding: 3px 7px !important;
  width: 80px;
}

.cancel-edit-column-name {
  height: 100%;
  padding-top: 4px;
  padding-left: 2px;
  width: 100%;
}

.edit-column-name {
  padding-left: 2px;
}

.cancel-edit-column-name i{
  vertical-align: middle;
}
</style>
