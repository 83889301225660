<template>
  <table-style-wrapper>
    <table-wrapper class="table-responsive">
      <a-table
        size="small"
        :pagination="false"
        :data-source="prices"
        :rowKey="(record) => $_.indexOf(record)"
      >
        <a-table-column key="selectItems">
          <template #default="{index}">
            <check-box
              class="select-items-checkbox"
              :field-value="!prices[index]['_destroy']"
              @update:fieldValue="updateIncluded(index, $event)"
            />
          </template>
        </a-table-column>

        <a-table-column key="hsCodes">
          <template #title>{{$t('models.priceCategory.attributes.hsCode')}}</template>
          <template #default="{record}">
            {{record.hsCode}}
          </template>
        </a-table-column>

        <a-table-column v-for="col in cols" :key="col">
          <template #title v-if="$_.includes(staticCols, col)">
            <span>{{$t(`models.priceCategory.attributes.${col}`)}}</span>
          </template>

          <template #title v-else>
            <editable-table-column
              :columnToEdit="columnToEdit"
              :column="col"
              :currentColumnName="editableCols[col]?.[$_.snakeCase('colName')]"
              @editColumn="columnToEdit = $event"
              @closeEditColumn="columnToEdit = undefined"
              @updateColumn="updateColumnName(col, $event)"
            />
          </template>

          <template #default="{record, index}">
            <number-field
              v-if="record"
              class="editable-table-cell hide-number-handler"
              :rules="record._destroy ? [] : [requiredNumber, nonNegative]"
              :disabled="record._destroy"
              size="small"
              :max="(col === 'duties' || col === 'vat') ? 100 : undefined"
              :field-value="prices[index][col]"
              :percentage="col === 'duties' || col === 'vat'"
              :fieldKey="[category, index, col]"
              @update:fieldValue="inputChanged(col, index, $event)"
            />
          </template>
        </a-table-column>
      </a-table>
    </table-wrapper>
  </table-style-wrapper>
</template>

<script>
import {
  computed, defineComponent, reactive, ref, watch
} from 'vue';
import {useI18n} from 'vue-i18n';
import VueTypes from 'vue-types';
import _ from 'lodash';
import {useStore} from 'vuex';
import {TableStyleWrapper} from '@/components/tables/style.js';
import {TableWrapper} from '@/components/shared/styledComponents/Main';
import NumberField from '@/components/shared/fields/Number';
import CheckBox from '@/components/shared/fields/CheckBox';
import EditableTableColumn from '../priceLists/EditableTableColumn.vue';
import {
  requiredNumber, integer, nonNegative
} from '@/helpers/validationRules';
import {priceListApprovalColumns} from '@/constants';

export default defineComponent({
  name: 'AgentHsPriceListTableForm',
  components: {
    TableWrapper,
    TableStyleWrapper,
    NumberField,
    CheckBox,
    EditableTableColumn
  },
  props: {
    country: VueTypes.string,
    category: VueTypes.string,
    unsavedPrices: VueTypes.object.def({}),
    unsavedColumns: VueTypes.object.def({})
  },
  emits: ['updateSubForm', 'updateColumnNames'],
  setup(props, context) {
    const {getters} = useStore();
    const {t} = useI18n();

    const staticCols = ['vat', 'duties'];
    const cols = ref([]);
    const editableCols = reactive({});
    const columnToEdit = ref(undefined);

    const categoryItems = computed(() => _.filter(getters.categoryItems, {categoryType: props.category}));
    const visibleItems = computed(() => {
      return _.filter(categoryItems.value, (item) => {
        return !item.deletedAt || _.find(priceLists.value, (p) => {
          return p._jv.relationships.priceCategoryItem._jv.id === item._jv.id;
        });
      });
    });

    const priceLists = computed(() => getters.companyPriceLists[props.country]?.[props.category] || []);
    const hsCodesColumns = computed(() => getters.hsCodesColumns);
    const prices = ref([]);

    watch(() => [visibleItems, priceLists], () => {
      prices.value = [];

      _.forEach(visibleItems.value, (item) => {
        const unsavedPrice = _.find(props.unsavedPrices, (price) => price.priceCategoryItemId === item._jv.id);
        let price;
        if (!unsavedPrice) {
          price = _.find(priceLists.value, (p) => {
            return p._jv.relationships.priceCategoryItem._jv.id === item._jv.id;
          });
        }

        prices.value.push({
          id: unsavedPrice?.id || price?._jv.id || '',
          priceCategoryItemId: item._jv.id,
          countryCode: props.country,
          hsCode: item.hsCode,
          duties: unsavedPrice ? unsavedPrice['duties'] : price?.duties || item.duties || 0,
          vat: unsavedPrice ? unsavedPrice['vat'] : price?.vat || item.vat || 0,
          col1: unsavedPrice ? unsavedPrice['col1'] : price?.col1 || 0,
          col2: unsavedPrice ? unsavedPrice['col2'] : price?.col2 || 0,
          col3: unsavedPrice ? unsavedPrice['col3'] : price?.col3 || 0,
          col4: unsavedPrice ? unsavedPrice['col4'] : price?.col4 || 0,
          col5: unsavedPrice ? unsavedPrice['col5'] : price?.col5 || 0,
          col6: unsavedPrice ? unsavedPrice['col6'] : price?.col6 || 0,
          _destroy: unsavedPrice ? unsavedPrice._destroy : !price
        });
      });

      context.emit('updateSubForm', prices.value);
    }, {immediate: true, deep: true});


    watch(() => hsCodesColumns, () => {
      cols.value = [...staticCols];
      if (!_.isEmpty(props.unsavedColumns)) {
        Object.assign(editableCols, props.unsavedColumns);
        cols.value.push(..._.map(props.unsavedColumns, 'key'));
      } else if (!_.isEmpty(getters.hsCodesColumns)) {
        Object.assign(editableCols, getters.hsCodesColumns);
        cols.value.push(..._.keys(getters.hsCodesColumns));
      } else {
        _.forEach(priceListApprovalColumns, (name, key) => {
          editableCols[key] = {
            [_.snakeCase('colName')]: t(`models.priceCategory.attributes.${name}`),
            id: undefined,
            [_.snakeCase('countryCode')]: props.country,
            key: key
          };
          cols.value.push(key);
        });
      }
      context.emit('updateColumnNames', editableCols);
    }, {immediate: true, deep: true});

    const inputChanged = (key, index, value) => {
      prices.value[index][key] = value;
      context.emit('updateSubForm', prices.value);
    };

    const updateIncluded = (index, isIncluded) => {
      prices.value[index]._destroy = !isIncluded;
      if (!isIncluded) {
        prices.value[index].duties = prices.value[index].vat = 0;
      }
      context.emit('updateSubForm', prices.value);
    };

    const updateColumnName = (col, value) => {
      editableCols[col][_.snakeCase('colName')] = value;
      context.emit('updateColumnNames', editableCols);
    };

    return {
      cols,
      staticCols,
      editableCols,
      columnToEdit,
      prices,
      categoryItems,
      requiredNumber, integer, nonNegative,
      priceLists,
      visibleItems,
      inputChanged,
      updateIncluded,
      updateColumnName
    };
  }
});
</script>

<style lang="scss">
.select-items-checkbox .ant-row.ant-form-item {
  height: 100%;
  margin-bottom: 0px !important;
}

.select-items-checkbox {
  padding-bottom: 0px !important;
  padding-top: 7px !important;
}
</style>
