<template>
  <sdCards headless>
    <template v-for="category in categories" :key= category >
      <h3>
        {{$t('views.registration.category')}}: {{$t(`models.priceCategory.attributes.categoryTypes.${category}`)}}
      </h3>
      <price-list-table
        v-if="category !== 'hs_code_list'"
        :currentCountry="country"
        :currentCategory="category"
      />
      <hs-price-list-table
        v-else
        :currentCountry="country"
        :currentCategory="category"
        :companyId="companyId"
      />
    </template>
  </sdCards>
</template>

<script>
import {
  defineComponent, computed
} from 'vue';
import {useStore} from 'vuex';
import _ from 'lodash';
import PriceListTable from '@/components/companyVerification/PriceListTable';
import HsPriceListTable from '@/components/companyVerification/HSPriceListTable';
import VueTypes from 'vue-types';

export default defineComponent({
  name: 'PriceListSummary',
  components: {
    PriceListTable,
    HsPriceListTable
  },
  props: {
    country: VueTypes.string,
    companyId: VueTypes.string
  },
  setup(props) {
    const {getters} = useStore();
    const priceLists = computed(() => getters.companyPriceLists);
    const categories = computed(() => {
      if (_.isEmpty(priceLists.value)) return;

      return _.keys(priceLists.value[props.country]);
    });

    return {
      priceLists,
      categories
    };
  }
});
</script>
